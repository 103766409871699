<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 14:38:50
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2023-01-19 12:48:19
-->
<template>
  <body-layer>
    <div class="base">
      <div class="xiao">复旦大学金融硕士辅导课程报名咨询联系方式：</div>
      <div class="sole">微信：18211059862（饼干老师）</div>
      <div class="sole">QQ：189235790（小央售前咨询） | 429832014（饼干老师）</div>
      <div class="sole">复旦大学金融硕士考研QQ群：386213852</div>
      <div class="codeList">
        <div class="item">
          <div class="image"><img src="@/assets/images/code1.png" alt="" /></div>
          <div class="title">饼干老师 微信</div>
        </div>
        <div class="item">
          <div class="image"><img src="@/assets/images/code2.png" alt="" /></div>
          <div class="title">金小央客服 QQ</div>
        </div>
        <div class="item">
          <div class="image"><img src="@/assets/images/code3.png" alt="" /></div>
          <div class="title">小央售前咨询 QQ</div>
        </div>
        <div class="item">
          <div class="image"><img src="@/assets/images/code4.png" alt="" /></div>
          <div class="title">复旦金融硕士考研 QQ群</div>
        </div>
      </div>
    </div>
  </body-layer>
</template>

<script>
import bodyLayer from '@/components/bodyLayer.vue';
export default {
  components: { bodyLayer }
}
</script>

<style lang="scss" scoped>
.base {
  width: 100%;
  background-color: #fff;
  padding: 0 80px;
  padding-top: 60px;
  padding-bottom: 80px;
  position: relative;

  .sole {
    padding-bottom: 30px;
    font-size: 24px;
    color: #333333;
  }

  .xiao {
    font-size: 16px;
    color: #666666;
    padding-bottom: 30px;
  }

  .codeList {
    display: flex;
    padding-top: 60px;
    padding-bottom: 80px;

    .item {
      flex: 1;

      .image {
        width: 160px;
        height: 160px;
        margin: 0 auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title {
        text-align: center;
        padding-top: 15px;
        color: #666666;
      }
    }
  }
}
</style>